.landing-box {
  position: relative;
  background-image: url("../../../assets/svgs/landing-background.svg");
  background-size: cover;
  padding: 20px 10%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}
.landing-logo {
  width: 150px;
}
.landing-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  row-gap: 40px;
  margin-top: 45px;
  z-index: 2;
}
.landing-textbox {
  text-align: center;
  max-width: 820px;
}
.landing-main-heading {
  color: white;
  text-align: center;
  font-size: 34px;
  font-weight: 700;
  line-height: 44px;
}
.landing-description {
  color: white;
  text-align: center;
  line-height: 26px;
}

.landing-buttons-wrapper {
  display: flex;
  flex-wrap: wrap;
  column-gap: 10px;
  row-gap: 10px;
  align-items: center;
  justify-content: center;
}

.landing-button {
  background: #2ca9dd;
  padding: 17px 30px;
  color: white;
  border-radius: 8px;
  border: none;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 14px;
  cursor: pointer;
  text-decoration: none;
}
.landing-privacy-button {
  border: 1px solid rgba(255, 255, 255, 0.39);
  padding: 17px 20px;
  color: white;
  border-radius: 8px;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 14px;
  cursor: pointer;
  text-decoration: none;
}

.landing-laptop-img {
  align-self: center;
  max-width: 100vw;
  margin-top: 20px;
}

.landing-footer {
  color: white;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 20px;
  flex-wrap: wrap;
}

.landing-footer a {
  color: whitesmoke;
}

@media screen and (max-width: 480px) {
  .landing-box {
    padding: 20px;
  }
  .landing-main-heading {
    font-size: 26px;
    line-height: 34px;
  }
  .landing-description {
    font-size: 14px;
    line-height: 20px;
  }
}
