.cursor-pointer {
  cursor: pointer;
}

.text-no-warp {
  text-wrap: nowrap;
}
.text-warp {
  text-wrap: wrap;
}
/* colors */
.bg-white {
  background-color: #ffffff;
}
.bg-gray {
  background-color: #f7f7fb;
}
.text-black {
  color: #000000;
}

.text-white {
  color: #ffffff;
}
.text-danger {
  color: #fc0808;
}
.text-success {
  color: #428702;
}
.text-gray {
  color: #878787;
}
.text-gray-500 {
  color: #0000007d;
}
.text-black-gray {
  color: #00000078;
}
.text-black-gray-50 {
  color: #0000007a;
}
.text-black-gray-100 {
  color: #0000008f;
}
.text-gray-light {
  color: #bdbdbd;
}
.text-primary {
  color: #29aee6;
}
.text-align-center {
  text-align: center;
}
.text-align-left {
  text-align: left;
}
.text-align-right {
  text-align: right;
}
/* width */
.width-70 {
  width: 70%;
}
.width-30 {
  width: 30%;
}
.max-full-width {
  max-width: 100%;
}
.width-half-46 {
  width: 46%;
}
/* height */
.h-31 {
  max-height: 31px;
}
.h-34 {
  height: 34px;
}
.h-45 {
  height: 42px !important;
}
.h-18 {
  height: 18px;
}
.h-51 {
  height: 51px;
}

.h-116 {
  height: 116px;
}
.h-142 {
  height: 142px;
}
.h-299 {
  height: 299px;
}
.h-441 {
  height: 441px;
}
.max-height-687 {
  max-height: 687px;
}
.h-684 {
  height: 684px;
}
.h-705 {
  height: 705px;
}
.h-767 {
  height: 767px;
}

.select-h input {
  height: 33px !important;
}
.h-full {
  height: 100%;
}
.max-full-height {
  max-height: 100%;
}
.width-full {
  width: 100%;
}
.full-height {
  height: calc(100vh - 260px);
}
.w-15 {
  width: 15px;
}
.w-83 {
  width: 83px;
}
.w-92 {
  width: 92px;
}
.w-118 {
  width: 118px;
}
.w-142 {
  width: 142px;
}
.w-186 {
  width: 186px;
}

.w-190 {
  width: 190px;
}
.max-w-214 {
  max-width: 214px;
}
.max-w-245 {
  width: 100%;
  max-width: 245px;
}
.max-w-744 {
  max-width: 744px;
}
.w-256 {
  width: 256px;
}
.w-286 {
  width: 286px;
}
.w-316 {
  width: 316px;
}
.w-326 {
  width: 326px;
}
.w-341 {
  width: 341px;
}
.max-width-286 {
  max-width: 286px;
}
.max-width-528 {
  max-width: 528px;
}
.w-527 {
  max-width: 527px;
}
.wfull {
  width: 100%;
}
.w-528 {
  width: 528px;
}
.w-666 {
  width: 666px;
}

.w-791 {
  width: 791px;
}
.w-1074 {
  max-width: 1074px;
}
.max-width-670 {
  max-width: 670px;
}

.w-1135 {
  width: 1135px;
}
.w-2000 {
  width: 2000px;
}

.max-width-1040 {
  width: 100%;
  max-width: 1040px;
}
.max-width-1074 {
  width: 100%;
  max-width: 1074px;
}
/* margin  */
.m-0 {
  margin: 0;
}
.mt-10 {
  margin-top: 10px;
}

.mt-12 {
  margin-top: 12px;
}
.mt-40 {
  margin-top: 40px;
}
.mt-42 {
  margin-top: 42px;
}
.mt-15 {
  margin-top: 15px;
}
.mt-14 {
  margin-top: 14px;
}
.mt-15 {
  margin-top: 15px;
}
.mt-16 {
  margin-top: 16px;
}
.mt-22 {
  margin-top: 22px;
}
.mt-27 {
  margin-top: 27px;
}
.mt-28 {
  margin-top: 28px;
}
.mt-22 {
  margin-top: 22px;
}
.mb-0 {
  margin-bottom: 0px;
}
.mb-12 {
  margin-bottom: 12px;
}
.mb-14 {
  margin-bottom: 14px;
}
.mb-20 {
  margin-bottom: 20px;
}
.mb-24 {
  margin-bottom: 24px;
}
.mb-27 {
  margin-bottom: 27px;
}
.mb-56 {
  margin-bottom: 56px;
}
.mt-16 {
  margin-top: 16px;
}
.mb-16 {
  margin-bottom: 16px;
}
.mb-10 {
  margin-bottom: 10px;
}
.mb-8 {
  margin-bottom: 8px;
}
.mb-6 {
  margin-bottom: 6px;
}
.mb-4 {
  margin-bottom: 4px;
}
.mb-40 {
  margin-bottom: 40px;
}
.mt-17 {
  margin-top: 17px;
}
.mt-18 {
  margin-top: 18px;
}
.mt-20 {
  margin-top: 20px;
}

.mt-24 {
  margin-top: 24px;
}
.mt-26 {
  margin-top: 26px;
}
.mt-30 {
  margin-top: 30px;
}
.mt-32 {
  margin-top: 32px;
}
.mt-34 {
  margin-top: 34px;
}
.mt-37 {
  margin-top: 37px;
}
.mt-50 {
  margin-top: 50px;
}
.mt-56 {
  margin-top: 56px;
}
.mt-47 {
  margin-top: 47px;
}

.mt-4 {
  margin-top: 4px;
}
.mr-4 {
  margin-right: 4px;
}
.mr-6 {
  margin-right: 6px;
}
.mr-12 {
  margin-right: 12px;
}
.mr-18 {
  margin-right: 18px;
}
.mr-20 {
  margin-right: 20px;
}
.mr-24 {
  margin-right: 24px;
}
.mr-34 {
  margin-right: 34px;
}

.mr-40 {
  margin-right: 40px;
}
.ml-20 {
  margin-left: 20px;
}
.ml-21 {
  margin-left: 21px;
}

.ml-27 {
  margin-left: 27px;
}
.ml-6 {
  margin-left: 6px;
}
.ml-10 {
  margin-left: 10px;
}
.ml-11 {
  margin-left: 11px;
}
.ml-14 {
  margin-left: 14px;
}
.ml-22 {
  margin-left: 22px;
}
.ml-24 {
  margin-left: 24px;
}
.ml-32 {
  margin-left: 32px;
}

.ml-35 {
  margin-left: 35px;
}
.ml-37 {
  margin-left: 37px;
}

@media (max-width: 1400px) {
  .ml-35 {
    margin-left: 10px; /* Adjust margin-left for smaller screens */
  }
}
.ml-16 {
  margin-left: 16px;
}
.ml-8 {
  margin-left: 8px;
}
.ml-12 {
  margin-left: 12px;
}

.mt-8 {
  margin-top: 8px;
}

/* padding */
.p-20 {
  padding: 20px;
}
.pr-16 {
  padding-right: 16px;
}
.pr-10 {
  padding-right: 10px;
}
.pl-16 {
  padding-left: 16px;
}
.pl-10 {
  padding-left: 10px;
}

.pr-50 {
  padding-right: 50px;
}
.pl-50 {
  padding-left: 50px;
}
.pr-24 {
  padding-right: 24px;
}
.pr-34 {
  padding-right: 34px;
}
.pl-22 {
  padding-left: 22px;
}
.pl-34 {
  padding-left: 32px;
}
.p-8 {
  padding: 8px;
}
.p-17 {
  padding: 1;
}
.p-8 {
  padding-top: 8px;
}
.pt-8 {
  padding-top: 8px;
}
.pt-24 {
  padding-top: 24px;
}
.pt-27 {
  padding-top: 27px;
}
.pb-40 {
  padding-bottom: 40px;
}

/* Font Size & Weight */
.font-8 {
  font-size: 8px;
}
.font-9 {
  font-size: 9px;
}
.font-10 {
  font-size: 10px;
}
.font-11 {
  font-size: 11px;
}
.font-12 {
  font-size: 12px;
}
.font-13 {
  font-size: 13px;
}
.font-14 {
  font-size: 14px;
}
.font-15 {
  font-size: 15px;
}
.font-16 {
  font-size: 16px;
}
.font-17 {
  font-size: 17px;
}
.font-20 {
  font-size: 20px;
}
.font-24 {
  font-size: 24px;
}
.font-400 {
  font-weight: 400;
}
.font-500 {
  font-weight: 500;
}
.font-600 {
  font-weight: 600;
}
.font-700 {
  font-weight: 700;
}

/* scroll body */
.scroll-body {
  height: calc(100vh - 230px);
  overflow: hidden;
}

/* main section */
.area-operation-spacing {
  padding: 20px 22px;
}

.main-section-spacing {
  margin-top: 16px;
  padding: 16px;
}

.main-section-spacing-lg {
  padding: 33px 37px 77px 39px;
  margin-top: 14px;
}
.main-section-spacing-md {
  padding: 28px 54px 37px 27px;
  margin-top: 14px;
}
.main-section-spacing-sm {
  padding: 32px 23px 29px 22px;
  margin-top: 14px;
}
.inner-section-shadow {
  padding: 16px 16px 25px 16px;
  box-shadow: 0px 4px 4px 0px #00000040;
}

.box-shadow-all {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  /* box-shadow: 0px 4px 4px 0px #00000040; */
}
.box-shadow-all-2 {
  box-shadow: 0px 4px 4px 0px #00000040;
}
.box-shadow-all-3 {
  box-shadow: 0px 2px 10px -2px #00000040;
}

/* Input Border  */
.type-input-focus:focus {
  border-color: #2ca9dd;
}
/* .type-input-focus:not(:placeholder-shown) {
  border-color: #2ca9dd;
} */

/* input field widths */

.input-w-196 {
  max-width: 196px;
}
.max-width-700 {
  max-width: 700px;
}
.pos-fixed {
  position: fixed;
}

/* flex property */
.flex {
  display: flex;
}
.flex-wrap {
  flex-wrap: wrap;
}
.flex-1 {
  flex: 1;
}
.gap-12 {
  gap: 12px;
}
.gap-10 {
  gap: 10px;
}
.gap-14 {
  gap: 14px;
}
.gap-16 {
  gap: 16px;
}
.gap-32 {
  gap: 32px;
}
.gap-8 {
  gap: 8px;
}
.gap-24 {
  gap: 20px;
}
.gap-29 {
  gap: 29px;
}
.gap-30 {
  gap: 30px;
}

.flex-col {
  flex-direction: column;
}
.flex-row {
  flex-direction: row;
}
.align-item-center {
  align-items: center;
}

.justify-content-center {
  justify-content: center;
}
.justify-content-between {
  justify-content: space-between;
}
.align-item-end {
  align-items: flex-end;
}
.align-item-start {
  align-items: flex-start;
}
.justify-content-end {
  justify-content: end;
}
.flex-shrink-1 {
  flex-shrink: 1;
}
/* borders */
.border-bottom-p {
  /* padding-bottom: 2px; */
  border-bottom: 2px solid #29aee6;
  width: max-content;
}
.border-solid-gray {
  border: 1px solid #ced4da;
}
.border-bottom-solid-gray {
  border-bottom: 1px solid #ced4da;
}
.border-primary-color {
  border-color: #29aee6 !important;
}

/* border box radius */

.border-radius-4 {
  border-radius: 4px;
}
.border-radius-6 {
  border-radius: 6px;
}
.border-radius-8 {
  border-radius: 8px;
}

/* Switch */
.switch-container {
  display: inline-block;
  position: relative;
}

.switch-checkbox {
  display: none;
}

.switch-label {
  display: block;
  /* width: 50px;
  height: 25px; */
  width: 42px;
  height: 18px;

  /* border-radius: 25px; */
  border-radius: 16px;
  cursor: pointer;
  position: relative;
}

.switch-button {
  position: absolute;
  top: 2px;
  left: 2px;
  /* width: 21px;
  height: 21px; */
  width: 22px;
  height: 14px;
  background-color: white;
  /* border-radius: 50%; */
  border-radius: 16px;
  transition: 0.3s;
}

.switch-on {
  /* left: 27px; */
  left: 18px;
  /* background-color: #4caf50; */
}
.switch-off-label-text {
  color: #ffffff;
  text-align: right;
  width: 100%;
  display: block;
  padding-right: 5px;
  /* background-color: #428702; */
  /* border: 1px solid red; */
  /* font-size: 12px; */
  box-sizing: border-box;
}
.switch-label-on {
  background-color: #4caf50;
}
.switch-label-off {
  background-color: #ccc;
}

/* MOBIO Badge */
.mo-badge-primary {
  background-color: #29aee6;
  border-radius: 10px;
  padding: 4px 10px;
  color: #ffffff;
}

/* Tables CSS */
/* table one style */
.custom-table-rm {
  width: 100%;
  background-color: white;
  border-collapse: collapse;
}

.custom-table-rm th,
.custom-table-rm td {
  padding: 8px 12px;
  text-align: left;
}

.custom-table-rm thead th {
  font-weight: bold;
  font-size: 15px;
  padding-top: 20px;
  padding-bottom: 20px;
  /* background-color: #fcfcfc; */
  background-color: #f9f9f9;
  border-radius: 6px;
}

.custom-table-rm th.location {
  width: 20%;
}

.custom-table-rm th.service-level {
  width: 60%;
}

.custom-table-rm th.action {
  width: 20%;
}

.custom-table-rm tbody tr {
  border-bottom: 1px solid #55565a1f;
}
.custom-table-rm tbody tr td {
  padding-top: 20px;
  padding-bottom: 20px;
}
.custom-table-rm tbody tr td:nth-child(1) {
  font-size: 14px;
  font-weight: 600;
}
.custom-table-rm tbody tr td:nth-child(1) img {
  width: 37px;
  height: 37px;
  border-radius: 50%;
}

.custom-table-rm tbody tr:last-child {
  border-bottom: none;
}

/* table one style v2 */
.custom-table-v2 {
  width: 100%;
  background-color: white;
  border-collapse: collapse;
}

.custom-table-v2 th,
.custom-table-v2 td {
  padding: 8px 12px;
  text-align: left;
  /* border-top: 2px solid #ebf1f96b; */
  /* border-bottom: 2px solid #ebf1f96b; */
}

.custom-table-v2 thead th {
  font-weight: bold;
  font-size: 15px;
  padding-top: 26px;
  padding-bottom: 26px;
  /* background-color: #fcfcfc; */
  /* background-color: #f9f9f9; */
  /* border-radius: 6px; */
}

.custom-table-v2 tbody tr {
  /* border-bottom: 1px solid #55565a1f; */
}
.custom-table-v2 tbody tr td {
  padding-top: 26px;
  padding-bottom: 26px;
}
/* .custom-table-v2 tbody tr td:nth-child(1) {
  font-size: 15px;
  font-weight: 500;
} */
.custom-table-v2 tbody tr td {
  font-size: 15px;
  font-weight: 500;
  line-height: normal;
}
.custom-table-v2 tbody tr td:nth-child(1) img {
  width: 37px;
  height: 37px;
  border-radius: 50%;
}

.custom-table-v2 tbody tr:last-child {
  /* border-bottom: none; */
}

.custom-table-hover {
  border: 2px solid #ebf1f96b;
}
/* .custom-table-hover:hover {
  border: 2px solid #29aee6 !important;
} */

/* table one style v3 */
.custom-table-v3 {
  width: 100%;
  background-color: white;
  border-collapse: collapse;
}

.custom-table-v3 th,
.custom-table-v3 td {
  text-align: left;
  border-bottom: 2px solid #ebf1f96b;
}

.custom-table-v3 thead th {
  font-weight: 600;
  font-size: 14px;
  padding-top: 28px;
  padding-bottom: 24px;
}
.custom-table-v3 thead tr th:nth-child(1) {
  padding-left: 24px;
}
.custom-table-v3 thead tr th:last-child {
  padding-right: 24px;
}

.custom-table-v3 tbody tr {
  /* border-bottom: 1px solid #55565a1f; */
}
.custom-table-v3 tbody tr td {
  padding-top: 18px;
  padding-bottom: 17px;
}
.custom-table-v3 tbody tr td:nth-child(1) {
  padding-left: 24px;
}
.custom-table-v3 tbody tr td {
  font-size: 13px;
  font-weight: 500;
  /* padding-left: 24px; */
}
/* .custom-table-v3 tbody tr td:nth-child(1) img {
  width: 37px;
  height: 37px;
  border-radius: 50%;
} */

.custom-table-v3 tbody tr:last-child {
  border-bottom: none;
}
.custom-table-v4 {
  width: 100%;
  background-color: white;
  border-collapse: collapse;
  table-layout: fixed; /* Ensures the table respects the width */
}

.custom-table-v4 th,
.custom-table-v4 td {
  text-align: left;
  border-bottom: 2px solid #ebf1f96b;
}

.custom-table-v4 thead th {
  font-weight: 600;
  font-size: 14px;
  padding-top: 28px;
  padding-bottom: 24px;
}

.custom-table-v4 thead tr th:nth-child(1) {
  padding-left: 24px;
}

.custom-table-v4 thead tr th:last-child {
  padding-right: 24px;
}

.custom-table-v4 tbody tr {
  height: auto; /* Remove fixed height if dynamic content height is needed */
}

.custom-table-v4 tbody tr td,
.custom-table-v4 tbody tr th {
  padding: 8px; /* Adjust padding as needed */
  vertical-align: middle; /* Center content vertically */
}

.custom-table-v4 tbody tr td:nth-child(1) {
  padding-left: 24px;
}

.custom-table-v4 tbody tr td {
  font-size: 13px;
  font-weight: 500;
}

.custom-table-v4 tbody tr:last-child {
  border-bottom: none;
}

/* Adjust widths dynamically */
.custom-table-v4 th,
.custom-table-v4 td {
  width: calc(100% / 8); /* Each part is 1/8th of the total width */
}

.custom-table-v4 th:nth-child(1),
.custom-table-v4 td:nth-child(1) {
  width: calc(100% / 8 * 2); /* First field gets double the width */
}

/* Add this to vertically center content in the cell */
.custom-table-v4 tbody tr td .flex {
  display: flex;
  align-items: center; /* Vertically centers items within the flex container */
  height: 100%; /* Ensures the div takes full height of the cell */
}

.custom-table-v4 tbody tr td .flex .distance-rate-table-icon {
  margin-right: 8px; /* Adjust spacing between icon and text */
}

/* Modal related Code */

.small-modal-section .MuiDialog-paperWidthSm {
  width: 540px;
  border-radius: 6px;
}
.small-modal-section .MuiDialogTitle-root {
  display: none;
}

.small-modal-section .MuiTypography-h6 {
  font-size: 16px;
}

.delete-modal-section .MuiDialog-paperWidthSm {
  width: 617px;
  border-radius: 11px;
}
.delete-modal-section .MuiTypography-h6 {
  font-size: 17px;
  font-weight: 500;
}

.small-medium-modal-section .MuiDialog-paperWidthSm {
  width: 617px;
  border-radius: 18px;
}
.small-medium-modal-section .MuiTypography-h6 {
  font-size: 16px;
}

.medium-modal-section .MuiDialog-paperWidthSm {
  width: 800px;
  border-radius: 18px;
}
.medium-modal-section .MuiDialogTitle-root {
  display: none;
}

.medium-modal-section .MuiTypography-h6 {
  font-size: 16px;
}

/* leaflet */
.leaflet-container {
  height: 70vh;
  width: 100%;
  border-radius: 4px;
}

/* Pre fix and post icon in the input field */
.input_field_zone {
  /* border: 1px solid #cacaca; */
  border: 0.2px solid #000000;
  position: relative;
  border-radius: 2px;
}
.input_field_zone_post_fix {
  position: absolute;
  top: 0;
  right: 0;
  border-left: 1px solid #b7b3b3;
  height: 100%;
  padding-left: 8px;
  padding-right: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.input_field_zone_post_fix p {
  margin: 0;
}
.input_field_zone input {
  border: none !important;
}
.input-field-increase-by {
  border: 0.2px solid #000000;
  border-radius: 2px;
  width: 119px;
  height: 27px;
  display: flex;
  align-items: center;
}
.input-field-increase-by input {
  border: none;
  width: 75%;
  outline: none;
}
.input-field-increase-by p {
  border-left: 1px solid #bdbdbd;
  padding-left: 7px;
}
/* remove arrow of the input field */
.remove-number-arrow input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
/* date timer  */
.react-datetime-picker__inputGroup__input:invalid {
  background-color: transparent !important;
}
.react-datetime-picker__inputGroup__input {
  outline: none;
}

/* Scroll Bar Common Css Style */
/* width */
::-webkit-scrollbar {
  width: 6px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/* airport card sm lg */

.airport-detail-card-section {
  width: 241px;
  height: 69px;
  background-color: #ffffff;
  border-radius: 10px;
  padding: 16px 20px;
  display: flex;
  border: 1px solid transparent;
  cursor: pointer;
}
.airport-detail-card-section:hover {
  border: 1px solid #29aee6;
}
.airport-detail-card-section .image-section {
  background-color: #24a7e912;
  width: 37px;
  height: 37px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.airport-detail-card-section .text-detail {
  margin-left: 15px;
}
.airport-detail-card-section .text-detail p:nth-child(1) {
  font-size: 16px;
  font-weight: 700;
}
.airport-detail-card-section .text-detail p:nth-child(2) {
  font-size: 12px;
  font-weight: 500;
}

/* airport card lg */
.airport-detail-card-section-lg {
  width: 241px;
  height: 206px;
  background-color: #ffffff;
  border-radius: 10px;
  padding: 38px 29px;
  border: 1px solid transparent;
  cursor: pointer;
  /* display: flex; */
}
.airport-detail-card-section-lg:hover {
  border: 1px solid #29aee6;
}
.airport-detail-card-section-lg .image-section {
  background-color: #24a7e912;
  width: 54px;
  height: 54px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.airport-detail-card-section-lg .text-detail {
  margin-top: 18px;
}
.airport-detail-card-section-lg .text-detail p:nth-child(1) {
  font-size: 11px;
  font-weight: 500;
}
.airport-detail-card-section-lg .text-detail p:nth-child(2) {
  margin-top: 12px;
  font-size: 16px;
  font-weight: 700;
}

/* activity notification */
.activity-notification {
}
.activity-notification .notification-icon {
  background-color: #24a7e912;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Date Picker */
.date-picker-selection input[type="date"]::-webkit-inner-spin-button,
.date-picker-selection input[type="date"]::-webkit-clear-button {
  display: none;
}

.date-picker-selection input[type="date"]::-webkit-calendar-picker-indicator {
  position: absolute;
  right: 10px;
  color: transparent;
  background: none;
  z-index: 2;
  cursor: pointer;
  height: 100%;
  width: 100%;
}
/* Formik Error Message */

.formik-error-message {
  color: #fc0808;
  font-size: 12px !important;
  font-weight: 400 !important;
}

/* Table no Record */
.table-no-record-section {
  width: 100%;
  font-size: 14px;
  font-weight: 500;
  color: #bdbdbd;
  text-align: center;
  padding-top: 8px;
  padding-bottom: 8px;
}

/* Popover list  start */
.name-container {
  display: flex;
  align-items: center;
  gap: 10px;
  flex-wrap: wrap;
  position: relative;
  justify-content: space-between;
  padding-right: 20px;
}

.name-badge {
  background-color: #29aee624;
  color: #1b94c7;
  padding: 4px 10px;
  border-radius: 12px;
  font-size: 10px;
  font-weight: 600;
}

.remaining-circle {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  background-color: #2ca9dd;
  color: #fff;
  border-radius: 50%;
  font-size: 12px;
  cursor: pointer;
  margin-left: 5px;
  position: relative;
}

.remaining-modal {
  position: absolute;
  top: 44px;
  left: 0;
  background-color: white;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 10px;
  z-index: 10;
  display: flex;
  gap: 5px;
  flex-wrap: wrap;
}

/* Button V2 */
.mo2-button:disabled {
  background-color: #2ca9dd;
  opacity: 0.6;
}

/* Tooltip */
/* Tooltip content style */
.primary-tooltip .MuiTooltip-tooltip {
  background-color: #000; /* Change background color */
  color: #fff; /* Change text color */
  font-size: 14px; /* Font size */
  padding: 10px; /* Padding */
  border-radius: 4px; /* Rounded corners */
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1); /* Optional shadow */
}

/* Tooltip arrow style */
.primary-tooltip .MuiTooltip-arrow {
  color: #000; /* Same color as background to make the arrow match */
}

.tooltip-icon-position {
  position: absolute;
  top: 0px;
  right: 0px;
}

/* clock timer */
.clock-timer {
  position: relative;
  border: 0.2px solid #000000;
  width: 100%;
  border-radius: 2px;
  display: flex;
  align-items: center;
}
.clock-timer-input {
  outline: none;
  border: none;
  width: 100%;
}

.clock-timer-active {
  z-index: 999999;
}

.clock-timer-inactive {
  z-index: 0;
}

.clock-options {
  position: absolute;
  top: 100%;
  left: 0;
  /* width: 100%; */
  width: 142px;
  /* height: 210px; */
  /* padding: 0.625rem; */
  background-color: white;
  border-radius: 4px;
  transition: all 0.3s ease;
  box-shadow: 0px 2px 10px -2px #00000040;
  overflow: hidden;
}

.clock-options-visible {
  z-index: 20;
  transform: translateY(0.25rem);
  visibility: visible;
  opacity: 1;
  /* display: block; */
}

.clock-options-hidden {
  /* display: none; */
  transform: translateY(1rem);
  visibility: hidden;
  opacity: 0;
}

.clock-options-hours,
.clock-options-minutes {
  /* flex: 1; */
  cursor: pointer;
  width: 40px;
  height: 102px;
  overflow: auto;
}
.clock-options-hours::-webkit-scrollbar,
.clock-options-minutes::-webkit-scrollbar {
  width: 0px;
}

.clock-option {
  /* padding: 6px; */
  padding-top: 6px;
  padding-bottom: 6px;
  color: #323232;
  font-size: 14px;
  text-align: center;
}
.clock-option:hover {
  color: #2ca9dd;
  border-radius: 4px;
  background-color: #29aee624;
}

.clock-option.selected {
  /* background-color: #f0f0f0; */

  color: #2ca9dd;
  font-weight: bold;
  font-size: 16px;
}

.clock-icon {
  position: absolute;
  right: 1rem; /* Tailwind's right-4 */
  top: 0.875rem; /* Tailwind's top-3.5 */
  height: 13px; /* Adjust as needed */
  width: 13px; /* Adjust as needed */
}

.clock-timer-cancel {
  border: none;
  font-family: Inter;
  font-size: 10px;
  font-weight: 500;
  line-height: 10.29px;
  color: #1c1c1c;
  background-color: transparent;
  padding: 5px 11px;
  cursor: pointer;
  border-radius: 2px;
}
.clock-timer-cancel:hover {
  border: 1px solid gray;
  background-color: gray;
  color: white;
}
.clock-timer-save {
  border: 0.6px solid #29aee6;
  border-radius: 2px;
  font-family: Inter;
  font-size: 10px;
  font-weight: 500;
  line-height: 10.29px;
  background-color: transparent;
  color: #29aee6;
  cursor: pointer;
  padding: 5px 11px;
}
.clock-timer-save:hover {
  background-color: #29aee6;
  color: white;
}
/* Date Time Picker Components */
.date-picker-v2 .react-datepicker {
  border: none !important;
  font-family: Inter;
}
.date-picker-v2 .react-datepicker__month-container {
  width: 275px;
  min-height: 270px;
  border-radius: 8px;
  box-shadow: 0px 2px 10px -2px #00000040;
  border: none !important;
}
.date-picker-v2 .react-datepicker__header {
  margin-top: 20px;
  background-color: transparent;
  border: none !important;
}
.date-picker-v2 .react-datepicker__current-month {
  font-family: Inter;
  font-size: 12px;
  font-weight: 500;
  line-height: 12.1px;
  color: #000000;
  width: 70%;
  margin: 0px auto;
  border-bottom: 1px solid #00000012;
  padding-bottom: 11px;
}
.date-picker-v2 .react-datepicker__navigation {
  margin-top: 16px;
  padding-bottom: 11px;
}
.date-picker-v2 .react-datepicker__day-names {
  margin-top: 14px;
}
.date-picker-v2 .react-datepicker__day-name {
  font-family: Inter;
  font-size: 12px;
  font-weight: 500;
  line-height: 13.31px;
  color: #323232;
  /* margin: 4px 5px; */
}

.date-picker-v2 .react-datepicker__day:hover {
  /* margin: 4px 5px; */
  background-color: #2ca9dd;
  color: #ffffff !important;
  border-radius: 50%;
}
.date-picker-v2
  .react-datepicker-popper[data-placement^="bottom"]
  .react-datepicker__triangle::after,
.date-picker-v2
  .react-datepicker-popper[data-placement^="bottom"]
  .react-datepicker__triangle::before {
  content: none !important;
  display: none !important;
}
.date-picker-v2 .react-datepicker__month {
  font-family: Inter;
  font-size: 11px;
  font-weight: 400;
  line-height: 13.31px;
}
.date-picker-v2 .react-datepicker__day--selected,
.date-picker-v2 .react-datepicker__day--keyboard-selected,
.date-picker-v2 .react-datepicker__day--keyboard-selected:hover {
  background-color: #2ca9dd;
  color: #ffffff !important;
  border-radius: 50%;
}

.sun-sat-highlight {
  color: #2ca9dd !important;
}

.date-picker-v2 .react-datepicker__navigation-icon--previous::before {
  content: none;
  display: none;
}
.date-picker-v2 .react-datepicker__navigation-icon--next::before {
  content: none;
  display: none;
}
.date-picker-v2 .react-datepicker__navigation--next {
  background: url(../icons/calendar_arrow-right.svg) no-repeat;
  border: none;
  right: 14px;
}
.date-picker-v2 .react-datepicker__navigation--previous {
  background: url(../icons/calendar_arrow-left.svg) no-repeat;
  border: none;
  left: 14px;
}
